<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('fair_report.balance_sheet') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="12" sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                      label-for="year"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="year"
                        :options="yearList"
                        v-model="search.year"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      label-for="fair_id"
                    >
                      <template v-slot:label>
                        {{ $t('tradeFairConfig.fair_name') }}
                      </template>
                      <b-form-select
                        disabled
                        plain
                        id="fair_id"
                        :options="dhakaFairList"
                        v-model="search.fair_id"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-for="circular_type"
                    >
                      <template v-slot:label>
                        {{ $t('ditfTradeFairManages.circular_type') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="circular_type"
                        :options="circularTypeList"
                        disabled
                        v-model="search.circular_type"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Stall Category" vid="stall_category" rules="" v-slot="{ errors }">
                    <b-form-group
                      label-for="stall_category"
                    >
                      <template v-slot:label>
                        {{ $t('fair_report.space_category') }}
                      </template>
                      <b-form-select
                        plain
                        id="stall_category"
                        :options="stallCategoryList"
                        v-model="search.stall_category"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <b-button :disabled="isResult" type="submit" size="sm" variant="primary" class="mt-20">
                      <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('fair_report.balance_sheet') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button variant="primary" v-if="finalList.length > 0" size="sm" @click="pdfExport" class="float-right">
            {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
            <template v-slot:projectNameSlot>
              {{ }}
            </template>
            {{ $t('fair_report.balance_sheet') }}
          </list-report-head>
          <b-row class="mb-2">
            <b-col md="12" class="text-left">
              <strong>{{ parseInt(search.fair_id) ? getSearchFairName(search.fair_id) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="12" class="text-left">
              <span>{{ $t('fair_report.space_category') }}</span>:
              <strong>{{ parseInt(search.stall_category) ? getStallCategoryName(search.stall_category) : $t('globalTrans.all') }}</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-overlay>
              <b-row>
                <b-col lg="12" sm="12">
                  <template>
                    <table style="width:100%;" class="mb-4 table-responsive">
                      <thead>
                        <tr>
                          <th class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                          <th class="text-center">{{ $t('fair_report.name_address_mobile') }}</th>
                          <th class="text-center">{{ $t('fair_report.stall_type') }}</th>
                          <th class="text-center">{{ $t('fair_report.processing_fee') }}</th>
                          <th class="text-center">{{ $t('fair_report.security_money') }}</th>
                          <th class="text-center">{{ $t('fair_report.allocated_stall') }}</th>
                          <th class="text-center">{{ $t('fair_report.allotment_fee') }}</th>
                          <th class="text-center">{{ $t('globalTrans.vat') }}</th>
                          <th class="text-center">{{ $t('globalTrans.tax') }}</th>
                          <th class="text-center">{{ $t('fair_report.refund_security_money') }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="finalList.length > 0">
                        <template v-for="(app, index) in finalList">
                          <tr v-for="(pavilion, index1) in app.stall_categories" :key="index1">
                            <slot v-if="index1 === 0">
                              <td :rowspan="app.stall_categories.length">{{ $n(index + 1) }}</td>
                              <td :rowspan="app.stall_categories.length">
                                <strong>{{ currentLocale == 'bn' ?  app.comp_name_bn : app.comp_name_en }}</strong> <br>
                                <strong>{{ app.mobile_no | mobileNumber }}</strong>
                                <br>
                                {{ AddressHelper.getCommonAddress(app.address) }}
                              </td>
                            </slot>
                            <td>{{ getStallCategoryName(pavilion.stall_cat_id) }}</td>
                            <td class="text-right">{{ $n(pavilion.processing_fee, { minimumFractionDigits: 2 }) }}</td>
                            <td class="text-right">{{ $n(pavilion.quoted_price * 0.25, { minimumFractionDigits: 2 }) }}</td>
                            <td>
                              <span v-if="app?.booked_pavilion.length > 0">
                                {{ getStallName(pavilion.stall_cat_id, app?.booked_pavilion) }}
                              </span>
                              <span v-else>{{ $t('fair_report.allocated_stall_zero') }}</span>
                            </td>
                            <td class="text-right">
                              <span v-if="app?.app_stall_payment_pay.length > 0">
                                {{ getStallPayment(pavilion.stall_cat_id, app?.app_stall_payment_pay) > 0 ? $n(getStallPayment(pavilion.stall_cat_id, app?.app_stall_payment_pay), { minimumFractionDigits: 2} ) : '' }}
                              </span>
                            </td>
                            <td class="text-right">
                              <span v-if="app?.app_stall_payment_pay.length > 0">
                                {{ getTax(pavilion.stall_cat_id, app?.app_stall_payment_pay) > 0 ? $n(getTax(pavilion.stall_cat_id, app?.app_stall_payment_pay), { minimumFractionDigits: 2} ) : '' }}
                              </span>
                            </td>
                            <td class="text-right">
                              <span v-if="app?.app_stall_payment_pay.length > 0">
                                {{ getVat(pavilion.stall_cat_id, app?.app_stall_payment_pay) > 0 ? $n(getVat(pavilion.stall_cat_id, app?.app_stall_payment_pay), { minimumFractionDigits: 2} ) : '' }}
                              </span>
                            </td>
                            <td class="text-right">
                              <span v-if="app?.app_refund.length > 0">
                                {{ getRefundAmount(pavilion.stall_cat_id, app?.app_refund) > 0 ? $n(getRefundAmount(pavilion.stall_cat_id, app?.app_refund), { minimumFractionDigits: 2} ) : '' }}
                              </span>
                            </td>
                          </tr>
                        </template>
                        <tr>
                            <td colspan="3" class="text-right">{{ $t('globalTrans.total') }}</td>
                            <td class="text-right">{{ $n(getTotalProcessingFee(), { minimumFractionDigits: 2}) }}</td>
                            <td class="text-right">{{ $n(getTotalSecurityMoney(), { minimumFractionDigits: 2}) }}</td>
                            <td></td>
                            <td class="text-right">{{ $n(getTotalStallFee(), { minimumFractionDigits: 2}) }}</td>
                            <td class="text-right">{{ $n(getTotalTax(), { minimumFractionDigits: 2}) }}</td>
                            <td class="text-right">{{ $n(getTotalVat(), { minimumFractionDigits: 2}) }}</td>
                            <td class="text-right">{{ $n(getTotalReturn(), { minimumFractionDigits: 2}) }}</td>
                          </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="10" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                </b-col>
                <b-col lg="6" sm="6">

                </b-col>
                <b-col lg="6" sm="6">
                  <table v-if="finalList.length > 0">
                      <tr>
                        <td colspan="2" class="text-center"><strong> {{ $t('fair_report.summary') }}</strong></td>
                      </tr>
                      <tr>
                        <td>{{ $t('globalTrans.total')+' '+$t('fair_report.processing_fee') }}</td>
                        <td class="text-right">{{ $n(getTotalProcessingFee(), { minimumFractionDigits: 2}) }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('globalTrans.total')+' '+$t('fair_report.security_money') }}</td>
                        <td class="text-right">{{ $n(getTotalSecurityMoney(), { minimumFractionDigits: 2}) }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('globalTrans.total')+' '+$t('fair_report.refund_security_money') }}</td>
                        <td class="text-right">{{ $n(getTotalReturn(), { minimumFractionDigits: 2}) }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('globalTrans.total')+' '+$t('fair_report.allotment_fee')+ ' ' + $t('fair_report.without_vat_tax') }}</td>
                        <td class="text-right">{{ $n(getTotalStallFee(), { minimumFractionDigits: 2})}}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('globalTrans.total')+' '+$t('globalTrans.vat') }}</td>
                        <td class="text-right">{{ $n(getTotalTax(), { minimumFractionDigits: 2}) }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('globalTrans.total')+' '+$t('globalTrans.tax') }}</td>
                        <td class="text-right">{{ $n(getTotalVat(), { minimumFractionDigits: 2}) }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('fair_report.total_callection_')}}</td>
                        <td class="text-right"><strong>{{  $n(getTotalIncludingVatTax(), { minimumFractionDigits: 2}) }}</strong></td>
                      </tr>
                      <tr>
                        <td>{{ $t('fair_report.total_callection') }}</td>
                        <td class="text-right">
                          <strong>{{ $n(getTotalExcludingVatTax(), { minimumFractionDigits: 2}) }}</strong>
                        </td>
                      </tr>
                    </table>
                </b-col>
              </b-row>
            </b-overlay>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfBalanceSheetReport, getMasterStalls } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import AddressHelper from '@/utils/area-type-address'

import ModalBaseMasterList from '@/mixins/list'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  created () {
    const currentFiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.$store.state.currentFiscalYearId)
    this.search.year = new Date(currentFiscalYear.end_date).getFullYear()
    // this.loadData()
    this.getStall()
  },
  data () {
    return {
      AddressHelper: AddressHelper,
      valid: null,
      baseUrl: internationalTradeFairServiceBaseUrl,
      sortBy: '',
      detailsItemId: '',
      isResult: false,
      priceType: 0,
      processingFee: 0,
      stallSize: '',
      circular: '',
      itemData: [],
      finalList: [],
      dhakaFairList: [],
      stallList: [],
      search: {
        year: 0,
        fair_id: 0,
        circular_type: 1,
        stall_category: 0,
        stall_id: 0
      },
      search_param: {
        year: 0,
        fair_id: 0,
        circular_type: 0,
        stall_category: 0,
        stall_id: 0
      },
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  watch: {
    'search.year': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dhakaFairList = this.dhakaFair()
      }
    },
    'search.stall_category': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.finalList = []
        // this.stallList = []
        this.search.stall_id = 0
        // this.stallList = this.getStall(newVal)
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
      }
    }
  },
  computed: {
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.EngBangNum(item.toString()) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    circularTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    stallCategoryList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getSearchParamName (Id, paramName) {
      let arrayData
      if (paramName === 'cType') {
        arrayData = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList
      } else {
        return ''
      }
      const Obj = arrayData.find(el => el.value === parseInt(Id))
      if (typeof Obj !== 'undefined') {
        return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
      } else {
        return ''
      }
    },
    getStallCategoryName (id) {
        const dataStallCategory = this.stallCategoryList.find(item => item.value === parseInt(id))
        if (typeof dataStallCategory !== 'undefined') {
          return this.$i18n.locale === 'en' ? dataStallCategory.text_en : dataStallCategory.text_bn
        } else {
          return ''
        }
    },
    getStallName (stallId, bookedPavilions) {
        const bookedStall = bookedPavilions.find(item => item.stall_cat_id === parseInt(stallId))

        if (bookedStall !== undefined) {
          const stallCategory = this.stallCategoryList.find(item => item.value === parseInt(bookedStall.stall_cat_id))

          if (parseInt(stallCategory.price_type) === 1) {
            return bookedStall.booked_stall_no
          } else {
            const stallData = this.stallList.find(item => item.value === parseInt(bookedStall.stall_id))
            return stallData !== undefined ? stallData.text : ''
          }
        } else {
          return ''
        }
    },
    getStallPayment (stallId, stallPayments) {
      const numericStallId = Number(stallId)
      const stallPayment = stallPayments.find(item => item.stall_cat_id === numericStallId)

      if (stallPayment) {
          const stallAmount = Number(stallPayment.payment_amount) - (Number(stallPayment.vat) + Number(stallPayment.tax))
          return stallAmount
      }
      return 0
    },
    getVat (stallId, stallPayments) {
      const numericStallId = Number(stallId)
      const stallPayment = stallPayments.find(item => item.stall_cat_id === numericStallId)
      if (stallPayment) {
          return stallPayment.vat
      }
      return 0
    },
    getTax (stallId, stallPayments) {
      const numericStallId = Number(stallId)
      const stallPayment = stallPayments.find(item => item.stall_cat_id === numericStallId)
      if (stallPayment) {
          return stallPayment.tax
      }
      return 0
    },
    getRefundAmount (stallId, stallsRefund) {
      const numericStallId = Number(stallId)
      const stallRefund = stallsRefund.find(item => item.stall_cat_id === numericStallId)
      if (stallRefund) {
          return stallRefund.refund_amount
      }
      return 0
    },
    getTotalProcessingFee () {
        return this.finalList.reduce((grandTotal, data) => {
            const stallCategories = data.stall_categories || []
            const totalForThisData = stallCategories.reduce((total, category) => {
                const fee = parseFloat(category.processing_fee) || 0 // Ensure valid number
                return total + fee
            }, 0)
            return grandTotal + totalForThisData
        }, 0)
    },
    getTotalSecurityMoney () {
        return this.finalList.reduce((grandTotal, data) => {
            const stallCategories = data.stall_categories || []
            const totalForThisData = stallCategories.reduce((total, category) => {
                const fee = parseFloat(category.quoted_price * 0.25) || 0 // Ensure valid number
                return total + fee
            }, 0)
            return grandTotal + totalForThisData
        }, 0)
    },
    getTotalStallFee () {
        return this.finalList.reduce((grandTotal, data) => {
            const stallPayments = data.app_stall_payment_pay || []
            const totalForThisData = stallPayments.reduce((total, stallPayment) => {
                const fee = Number(stallPayment.payment_amount) - (Number(stallPayment.vat) + Number(stallPayment.tax)) || 0 // Ensure valid number
                return total + fee
            }, 0)
            return grandTotal + totalForThisData
        }, 0)
    },
    getTotalVat () {
        return this.finalList.reduce((grandTotal, data) => {
            const stallPayments = data.app_stall_payment_pay || []
            const totalForThisData = stallPayments.reduce((total, stallPayment) => {
                const fee = Number(stallPayment.vat) || 0 // Ensure valid number
                return total + fee
            }, 0)
            return grandTotal + totalForThisData
        }, 0)
    },
    getTotalTax () {
        return this.finalList.reduce((grandTotal, data) => {
            const stallPayments = data.app_stall_payment_pay || []
            const totalForThisData = stallPayments.reduce((total, stallPayment) => {
                const fee = Number(stallPayment.tax) || 0 // Ensure valid number
                return total + fee
            }, 0)
            return grandTotal + totalForThisData
        }, 0)
    },
    getTotalReturn () {
        return this.finalList.reduce((grandTotal, data) => {
            const stallReturns = data.app_refund || []
            const totalForThisData = stallReturns.reduce((total, stallReturn) => {
                const fee = Number(stallReturn.refund_amount) || 0 // Ensure valid number
                return total + fee
            }, 0)
            return grandTotal + totalForThisData
        }, 0)
    },
    getTotalIncludingVatTax () {
          const total = (
              (this.getTotalProcessingFee() || 0) +
              (this.getTotalStallFee() || 0) +
              (this.getTotalVat() || 0) +
              (this.getTotalTax() || 0) +
              (this.getTotalSecurityMoney() || 0) - (this.getTotalReturn() || 0)
          )
          return total
    },
    getTotalExcludingVatTax () {
      const total = (
          (this.getTotalProcessingFee() || 0) +
          (this.getTotalStallFee() || 0) +
          (this.getTotalSecurityMoney() || 0) - (this.getTotalReturn() || 0)
      )
      return total
    },
    dhakaFair () {
      this.search.fair_id = 0
      return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
        if (item !== '') {
          this.search.fair_id = item.value
        }
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.isResult = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, ditfBalanceSheetReport, this.search)
      if (result.success) {
        this.finalList = result.data
        this.isResult = false
      } else {
        this.isResult = false
        this.finalList = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getSecurityMoney (data) {
        const Obj = this.$store.state.TradeFairService.ditfCommonObj.securityMoneyList.find(item => item.year === this.search.year)
        const percentage = Obj !== undefined ? Obj.percentage : 0
        return (percentage * data) / 100
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getFloorPrice (catId) {
      const cat = this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.find(item => item.value === catId)
      if (cat !== undefined) {
        this.priceType = cat.price_type
        this.processingFee = cat.processing_fee
        this.stallSize = this.$i18n.locale === 'bn' ? cat.size_bn : cat.size_en
        return cat.floor_price
      } else {
        return 0
      }
    },
    getSearchFairName (id) {
      const companyType = this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getCategoryName (id) {
      const companyType = this.stallCategoryList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    async getStall (stallCat) {
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, getMasterStalls)
      if (result.success) {
        this.stallList = result.data
      } else {
        this.stallList = []
      }
    },
    async pdfExport () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign(
        {
        request_type: 'pdf',
        local: this.$i18n.locale,
        accounts: 0,
        org_id: 2,
        year: this.search.year,
        stall_category: this.search.stall_category,
        fair_id: this.search.fair_id,
        circular_type: this.search.circular_type,
        total_processing_fee: this.getTotalProcessingFee(),
        total_security_fee: this.getTotalSecurityMoney(),
        total_stall_fee: this.getTotalStallFee(),
        total_vat: this.getTotalVat(),
        total_tax: this.getTotalTax(),
        total_refund: this.getTotalReturn(),
        grand_t_including_v_t: this.getTotalIncludingVatTax(),
        grand_t_excluding_v_t: this.getTotalExcludingVatTax()
        })
      const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, ditfBalanceSheetReport, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
</style>
